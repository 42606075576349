:root {
  --teal: #85c7d6; 
}

.App {
  height: 100%;
  width: 100%;
  cursor: default;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* supported by Chrome, Edge, Opera and Firefox */
  background-color: #2A2A2A;
}

.App-header {
  background-color: #282c34;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  color: white;
  height: 10%;
  padding: 0px 30px;
}

h1 {
  font-size: calc(10px + 2vmin);
  font-weight: unset;
  margin-block-end: unset;
  margin-block-start: unset;
}

h2 {
  font-size: calc(6px + 1.5vmin);
  font-weight: unset;
  margin-block-end: unset;
  margin-block-start: unset;
}

.App-header-box {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
}

.menu-icon:hover {
  background-color: grey;
}

.App-header-underline.selected {
  border: 3px solid var(--teal);
  border-radius: 5px;
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: -10px;
}

.App-header-login {
  position: fixed;
  top: 10px;
  right: 60px;
  font-size: 18px;
}

.App-body {
  position: relative;
  top: 0;
  overflow-y: scroll;
  height: 90%;
  min-height: 90%;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */  
}

.App-body::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.blog::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.infopanel-inner::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}

.App-timeline-rows {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.timelineElement {
  width: 600px;
  border-width: 10px;
  text-align: left;
  font-size: 16px;
}

.timelineCircle.film {
  margin: 5px 0px;
  font-size: 18px;
}

.timelineCircle {
  color: grey;
}

.timelineCircle.videogame {
  color: purple;
}

.timelineCircle.comic {
  color: #408040;
}

.timelineCircle.novel {
  color: #cc8000;
}

.timelineCircle.tv {
  color: #404080;
}

.timelineElement.film {
  font-weight: bold;
  font-size: 24px;
}

.timeline-row {
  width: 66% !important;
  left: 135px !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.timelineElementWrapper {
  display: flex;
  background-color: black;
  border-radius: 12px;
  border: solid grey;
  padding-top: 1px;
  padding-bottom: 1px;
  color: white;
  width: 50%;
  height: 22px;
  overflow: hidden;
}

.timelineElementWrapper.videogame {
  border: solid purple;
}

.timelineElementWrapper.comic {
  border: solid #408040;
}

.timelineElementWrapper.novel {
  border: solid #cc8000;
}

.timelineElementWrapper.tv {
  border: solid #404080;
}

.timelineElementWrapper.film {
  height: 38px;
}

.timelineElementWrapper.selected {
  background-color: var(--teal);
}

.timelineElementWrapper:hover {
  background-color: grey;
}

.scroll-list {
  scrollbar-width: none;
}

.infopanel-wrapper {
  width: 40%;
  height: 80%;
  position: fixed;
  top: 15%;
  right: 0%;
  z-index: 2;
}

.infopanel {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  border: solid darkcyan;
  background-color: darkcyan;
}

.header-box {
  justify-content: space-between;
  display: flex;
}

.infopanel-inner {
  margin: 5px;
  border: white;
  background-color: var(--teal);
  border-style: solid;
  padding: 5px;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */  
}

.title {
  font-size: 20px;
}

button {
  border-color: darkcyan;
  background-color: var(--teal);
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
  padding: 2px 10px;
  color: black;
}

button:hover {
  background-color: grey;
}

.modal-button {
  position: absolute;
  right: 10px;
  background-color: black;
  color: white;
}

.link-text {
  padding: 2px 20px;
  border-radius: 10px;
}

.link-text:hover {
  background-color: grey;
}

button.selected {
  background-color: darkcyan;
}

.blog-button {
  color: black;
  background-color: var(--teal);
  border: solid darkcyan;
  border-width: 4px;
}

.modal-header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;
}

li {
  padding: 3px 0px;
}

.filter-button {
	border: 2px solid black;
  clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 10% 100%);
	height: 20px;
	width: 125px;
  background-color: #282c34;
  text-align: center;
  color: white;
  transform: translate(-50%, 0%);
  position: fixed;
  top: 10%;
}

input {
  float: right;
  margin-left: 20px;
  padding-left: 5px;
}

.input-container {
  margin-top: 5px;
}

.image {
  height: 300px;
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-page {
  padding: 30px;
  background-color: white;
}

.blog {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 30px;
  justify-content: flex-end;
}

.blog-inner {
  width: 50%;
  background-color: white;
}

.side-nav {
  width: 30%;
  background-color: white;
}

.blogEntry {
  padding: 10px;
  border: solid black;
  border-width: 4px;
}

.blog-body {
  display: flex;
}

.blog-text {
  margin-bottom: 10px;
}

.blog-header {
  text-align: center;
  background-color: white;
  border: solid black;
  border-width: 4px;
  padding: 10px;
  margin: 5px;
}

.side-panel-container {
  position: fixed;
  align-self: center;
  margin-left: 15px;
  top: 15%;
  z-index: 2;
  pointer-events: none;
}

.filter-panel {
  border-radius: 12px;
  border: solid grey;
  border-width: 6px;
  color: white;
  background: #282c34;
  padding: 10px;
  width: 300px;
  height: fit-content;
  margin-bottom: 20px;
  pointer-events: auto;
  z-index: 2;
  position: relative;
}

.content-suggestion {
  display: flex;
}

.ReactModal__Content {
  inset: unset !important;
  position: fixed !important;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  border-radius: 12px !important;
  border: solid darkcyan !important;
  border-width: 6px !important;
  color: black;
  background: var(--teal) !important;
  max-height: 90%;
}

.ReactModal__Overlay {
  z-index: 5;
}

.menu-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 5px 2px 5px;
  border-radius: 10px;
  position: relative;
  font-size: medium;
}

.icon {
  height: 3.5vh;
}

.small-icon {
  height: 2vh;
}

.menu-icon-row {
  width: 35px;
  height: 5px;
  background-color: black;
  margin: 6px 0;
}

.profile-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
}

.profile {
  width: 40%;
}

.profile-title {
  font-size: calc(10px + 2vmin);
  border: solid white;
  text-align: center;
  background-color: var(--teal);
  padding: 5px;
}

.profile-icons {
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  align-items: center;
}

.subtitle {
  font-size: 20px;
  text-align: center;
}

.profile-panel {
  border: white;
  background-color: var(--teal);
  border-style: solid;
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.tooltip {
  position: relative;
}

.tooltip .tooltip-text {
  visibility: hidden;
  width: 140px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  font-size: 12px;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -75px;
}
.tooltip:hover .tooltip-text {
  visibility: visible;
}

footer {
  background-color: #282c34;
  height: 40px;
  color: white;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: sticky;
  top: 100%;
}

a {
  color: unset;
  text-decoration: unset;
}

.mobile-only {
  display: none;
}

.era-icon {
  height: 8vh;
  font-size: small;
  text-align: center;
  color: white;
  margin: 0vh 0vh 3vh 0vh;
}

.progress-bar-outer {
  background-color: grey;
  border-radius: 13px;
  margin: 10px;
  border: solid black;
}

.progress-bar-inner {
  width: 0%;
  height: 30px;
  background-color: darkcyan;
  text-align: center;
  line-height: 30px;
  color: white;
  border-radius: 10px;
}

.commentInput {
  width: 50%;
  height: 120px;
}

.comment {
  margin-bottom: 10px;
  border: 2px solid darkcyan;
  border-radius: 10px;
  padding: 10px;
}

.comment-text {
  text-wrap: pretty;
  margin-bottom: 10px;
}

.clickable:hover {
  cursor: pointer;
}

.clickable.selected {
  color: darkcyan;
}

.small-text {
  font-size: small;
  display: inline;
  padding-left: 5px;
}

.achievement {
  height: 80px;
  width: 80px;
  margin: 10px;
}

.profile-pic {
  height: 80px;
  width: 80px;
}

.profile-pic.selected {
  border: 5px solid red;
  box-sizing: border-box;
}

.unearned {
  filter: grayscale(1.0) opacity(0.3);
}

/* css specific to mobile devices */
@media screen and (max-width: 1300px) {
  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: unset;
  }

  .timelineElementWrapper, .timeline-row, .infopanel-wrapper {
    width: 100%;
  }

  .App-header-box {
    display: flex;
  }

  .side-panel-container {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }

  .modal-background.enabled {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.75);
    position: fixed;
    top: 0px;
    left: 0px;
    pointer-events: auto;
  }

  .disabled {
    display: none;
  }

  .blog-inner {
    width: 100%;
  }

  .profile {
    width: 100%;
  }

  .menu-icon {
    font-size: small;
  }

  .side-nav {
    display: none;
  }

  .commentInput {
    width: 99%;
  }
}

/* css specific to mobile devices */
@media screen and (max-width: 700px) {
  .App-header {
    background-color: #282c34;
    display: flex;
    flex-direction: column-reverse;
    padding: 0px;
  }

  .App-header-box {
    width: 100%;
  }

  .timeline-row {
    left: 0px !important;
    width: 95% !important;
  }

  .era-icon {
    display: none;
  }

  .blog-body {
    flex-direction: column !important;
    align-items: center;
  }

  .ReactModal__Content {
    transform: translate(0%, -50%) !important;
    left: 0px !important;
    right: 0px !important;
  }

  .menu-icon {
    font-size: x-small;
    text-align: center;
    margin-top: 5px;
    padding: 0px 3px;
  }

  .menu-sub-icon {
    flex-direction: row;
  }
}
